import React, {FC} from 'react';

import Layout from '../components/layout/layout';
import HomePage from './home';

const IndexPage: FC = () => (
  <div className="App">
    <Layout>
      <HomePage />
    </Layout>
  </div>
);

export default IndexPage;
